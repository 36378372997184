import { Button, Stack, Text, Title } from "@mantine/core";
import MainLayout from "components/layouts/MainLayout";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

const SignupTop: FC = () => {
  const navigate = useNavigate();

  return (
    <MainLayout footer={false}>
      <Title size={18} align="center" c="#333" mb={30}>
        いちやまマート公式アプリ
      </Title>
      <Stack spacing="xl">
        <Text color="#333" size={18} align="center">
          お持ちのモアカードを登録することで、
          <br />
          スマートフォンをモアカードとして
          <br />
          利用できるようになります！
        </Text>
        <Button
          size="lg"
          fz={16}
          fullWidth
          onClick={() => navigate("/signup/new")}
        >
          次へ
        </Button>
      </Stack>
    </MainLayout>
  );
};

export default SignupTop;
