import { useGetUser } from "api";
import type MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import { handleMoveToErrorPage } from "libs/handleError";
import type { ComponentProps, FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";

type RequireCardRegisterProps = Pick<
  ComponentProps<typeof MainLayout>,
  "pageTitle"
> & {
  children: ReactNode;
  /**
   * カード登録済み・未登録
   *
   * - カード登録済みユーザーのみアクセスできる画面の場合: true
   * - カード未登録ユーザーのみアクセスできる画面の場合: false
   */
  isRequired?: boolean;
};

/**
 * カード登録状況をチェックするコンポーネント
 *
 * 参考: https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
 */
const RequireCardRegister: FC<RequireCardRegisterProps> = ({
  isRequired = true,
  pageTitle,
  children,
}) => {
  const { isLoading, data, error } = useGetUser({
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  });

  // ロード中
  if (isLoading) return <Loading header={false} pageTitle={pageTitle} />;

  // API 接続自体に失敗した場合はエラーページへ
  if (!data && !error) {
    handleMoveToErrorPage("server-error");
    return null;
  }

  // カード登録済みかどうか
  const isCardRegistered =
    !error?.errors.includes("カードが登録されていません");

  if (isRequired && !isCardRegistered) return <Navigate to="/signup/top" />;

  if (!isRequired && isCardRegistered) return <Navigate to="/" />;

  return children;
};

export default RequireCardRegister;
