import { Flex, Text } from "@mantine/core";
import type { FC } from "react";

type MonthlyHistoryLabelProps = {
  monthName: string;
  purchaseAmount: number;
};
const MonthlyHistoryItem: FC<MonthlyHistoryLabelProps> = ({
  monthName,
  purchaseAmount,
}) => (
  <Flex align="center" justify="space-between">
    <Text weight={400} size={14} component="time">
      {monthName}月
    </Text>
    <Text weight="bold" size={16}>
      {new Intl.NumberFormat().format(purchaseAmount)}
      <Text component="span" weight={400} size={14}>
        円
      </Text>
    </Text>
  </Flex>
);
export default MonthlyHistoryItem;
