import {
  Accordion,
  Badge,
  createStyles,
  Flex,
  List,
  rem,
  Text,
} from "@mantine/core";
import { ReactComponent as MapPin } from "assets/images/icons/map_pin.svg";
import type { FC, ReactElement } from "react";

const useStyles = createStyles(() => ({
  badge: {
    backgroundColor: "#EEEEEE",
    color: "#9E9E9E",
    fontSize: rem(12),
    height: rem(20),
    padding: 0,
    width: rem(20),
  },
  control: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  item: {
    "&[data-active] > button > span div": {
      fontWeight: 700,
    },
  },
}));

type MyStoreAccordionsProps = {
  children: ReactElement;
  prefectureName: string;
  storeCount: number;
};
const StoresAccordionItem: FC<MyStoreAccordionsProps> = ({
  children,
  prefectureName,
  storeCount,
}) => {
  const { classes } = useStyles();
  return (
    <Accordion.Item className={classes.item} value={prefectureName}>
      <Accordion.Control className={classes.control} icon={<MapPin />}>
        <Flex justify="space-between">
          <Text size={14} weight={400}>
            {prefectureName}
          </Text>
          <Badge color="gray" radius="xl" className={classes.badge}>
            {storeCount}
          </Badge>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel>
        <List
          styles={{
            root: {
              display: "flex",
              flexDirection: "column",
              gap: rem(8),
            },
          }}
          spacing={8}
          role="listbox"
          listStyleType="none"
        >
          {children}
        </List>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
export default StoresAccordionItem;
