import { Accordion, Stack, Title, Transition } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import { ReactComponent as CaretDownIcon } from "assets/images/icons/caret_down.svg";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import MyStoreStatusBar from "components/modules/MyStoreStatusBar";
import { useGetAllStores } from "features/myStores/api";
import StoreCard from "features/myStores/components/StoreCard";
import StoresAccordionItem from "features/myStores/components/StoresAccordionItem";
import useMyStoreMethods from "features/myStores/hooks/useMyStoreMethods";
import type {
  GetStoresResponseData,
  StoresInPrefecture,
} from "features/myStores/types/index";
import { PAGE_TITLE, PREFECTURES } from "libs/constants";
import type { FC } from "react";
import { useCallback, useState } from "react";

const transformDataToStoresInPrefectureType = (
  data?: GetStoresResponseData
): StoresInPrefecture[] | undefined => {
  if (data) {
    return PREFECTURES.map(({ value }) => ({
      prefecture: value,
      stores: data.stores.filter((store) => store.prefecture === value),
    })).filter((prefectureStores) => prefectureStores.stores.length > 0);
  }
  return undefined;
};
const MyStoresEdit: FC = () => {
  const { data: allStoresData, isValidating } = useGetAllStores();
  const prefectureStores = transformDataToStoresInPrefectureType(allStoresData);
  const {
    isMyStore,
    editMyStoreCreator,
    isValidating: isMutating,
  } = useMyStoreMethods();
  const [statusText, setStatusText] = useState<undefined | string>(undefined);
  const { start, clear } = useTimeout(() => setStatusText(undefined), 1000);
  const onSuccessHandler = useCallback(
    (id: number) => () => {
      if (isMyStore(id)) {
        setStatusText("マイ店舗から削除しました");
      } else {
        setStatusText("マイ店舗に登録しました");
      }
      clear();
      start();
    },
    [start, clear, isMyStore]
  );

  if (isValidating || !prefectureStores)
    return <Loading pageTitle={PAGE_TITLE.ICHIYAMA_MART} />;

  return (
    <MainLayout pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
      <Transition
        transition={{
          in: { opacity: 0.9 },
          out: { opacity: 0 },
          transitionProperty: "transform, opacity",
        }}
        mounted={Boolean(statusText)}
      >
        {(style) => <MyStoreStatusBar statusText={statusText} style={style} />}
      </Transition>
      <Stack spacing={24}>
        <Title size={18}>店舗検索</Title>
        <Accordion
          role="tree"
          multiple
          chevron={<CaretDownIcon />}
          styles={{
            content: {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          {prefectureStores.map(({ prefecture, stores }) => (
            <StoresAccordionItem
              key={`store_${prefecture}`}
              prefectureName={prefecture}
              storeCount={stores.length}
            >
              <>
                {stores.map((store, index) => (
                  <StoreCard
                    isMyStore={isMyStore(store.id)}
                    isMutating={isMutating}
                    store={store}
                    key={`store_${index}`}
                    onClickMyStoreButton={editMyStoreCreator(
                      store.id,
                      onSuccessHandler(store.id)
                    )}
                  />
                ))}
              </>
            </StoresAccordionItem>
          ))}
        </Accordion>
      </Stack>
    </MainLayout>
  );
};

export default MyStoresEdit;
