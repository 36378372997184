import { usePostCardReregister } from "api";
import CardRegisterConfirm from "features/user/components/cardRegister/CardRegisterConfirm";
import type { FC } from "react";

const CardReregisterConfirm: FC = () => {
  const { trigger, isMutating } = usePostCardReregister();

  return (
    <CardRegisterConfirm
      errorPath="/my-page/card-reregister/new"
      isMutating={isMutating}
      registerButtonPath="/my-page/card-reregister/complete"
      trigger={trigger}
    />
  );
};

export default CardReregisterConfirm;
