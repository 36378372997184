import { PAGE_TITLE } from "libs/constants";
import Home from "pages/Home";
import RootBoundary from "pages/RootBoundary";
import AccountDetail from "pages/myPage/AccountDetail";
import MyPage from "pages/myPage/MyPage";
import CardReregisterComplete from "pages/myPage/cardReregister/CardReregisterComplete";
import CardReregisterConfirm from "pages/myPage/cardReregister/CardReregisterConfirm";
import CardReregisterNew from "pages/myPage/cardReregister/CardReregisterNew";
import CardReregisterTop from "pages/myPage/cardReregister/CardReregisterTop";
import MyStores from "pages/myStores/MyStores";
import MyStoresEdit from "pages/myStores/MyStoresEdit";
import DailyPurchaseHistories from "pages/purchaseHistories/DailyPurchaseHistories";
import MonthlyPurchaseHistories from "pages/purchaseHistories/MonthlyPurchaseHistories";
import SignupComplete from "pages/signup/SignupComplete";
import SignupConfirm from "pages/signup/SignupConfirm";
import SignupNew from "pages/signup/SignupNew";
import SignupTop from "pages/signup/SignupTop";
import type { FC } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import RequireCardRegister from "router/RequireCardRegister";

// eslint-disable-next-line import/no-named-export
export const routes = [
  {
    children: [
      {
        element: (
          <RequireCardRegister isRequired>
            <Home />
          </RequireCardRegister>
        ),
        index: true,
      },
      // 初期画面
      {
        element: (
          <RequireCardRegister
            isRequired={false}
            pageTitle={PAGE_TITLE.CARD_REGISTER}
          >
            <SignupTop />
          </RequireCardRegister>
        ),
        path: "/signup/top",
      },
      // カード登録_入力画面
      {
        element: (
          <RequireCardRegister
            isRequired={false}
            pageTitle={PAGE_TITLE.CARD_REGISTER}
          >
            <SignupNew />
          </RequireCardRegister>
        ),
        path: "/signup/new",
      },
      // カード登録_確認画面
      {
        element: (
          <RequireCardRegister
            isRequired={false}
            pageTitle={PAGE_TITLE.CARD_REGISTER}
          >
            <SignupConfirm />
          </RequireCardRegister>
        ),
        path: "/signup/confirm",
      },
      // カード登録_登録完了画面
      {
        element: (
          <RequireCardRegister pageTitle={PAGE_TITLE.CARD_REGISTER}>
            <SignupComplete />
          </RequireCardRegister>
        ),
        path: "/signup/complete",
      },
      // Phase1 では対象外になったので、コメントアウト
      // // カード登録_カウンター経由_入力画面
      // { element: <SignupCounterConnect />, path: "/signup/counter/connect" },
      // マイページ画面
      {
        element: (
          <RequireCardRegister isRequired>
            <MyPage />
          </RequireCardRegister>
        ),
        path: "/my-page",
      },
      // 会員情報画面
      {
        element: (
          <RequireCardRegister isRequired>
            <AccountDetail />
          </RequireCardRegister>
        ),
        path: "/my-page/account",
      },
      // カード再登録
      {
        element: (
          <RequireCardRegister isRequired>
            <CardReregisterTop />
          </RequireCardRegister>
        ),
        path: "/my-page/card-reregister/top",
      },
      // カード再登録_入力画面
      {
        element: (
          <RequireCardRegister isRequired>
            <CardReregisterNew />
          </RequireCardRegister>
        ),
        path: "/my-page/card-reregister/new",
      },
      // カード再登録_確認画面
      {
        element: (
          <RequireCardRegister isRequired>
            <CardReregisterConfirm />
          </RequireCardRegister>
        ),
        path: "/my-page/card-reregister/confirm",
      },
      // カード再登録_登録完了画面
      {
        element: (
          <RequireCardRegister isRequired>
            <CardReregisterComplete />
          </RequireCardRegister>
        ),
        path: "/my-page/card-reregister/complete",
      },
      // マイ店舗_トップ
      {
        element: (
          <RequireCardRegister isRequired pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
            <MyStores />
          </RequireCardRegister>
        ),
        path: "/my-stores",
      },
      // 店舗検索
      {
        element: (
          <RequireCardRegister isRequired pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
            <MyStoresEdit />
          </RequireCardRegister>
        ),
        path: "/my-stores/edit",
      },
      // お買い物履歴(月)
      {
        element: (
          <RequireCardRegister isRequired pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
            <MonthlyPurchaseHistories />
          </RequireCardRegister>
        ),
        path: "/purchase-histories",
      },
      // お買い物履歴(日)
      {
        element: (
          <RequireCardRegister isRequired pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
            <DailyPurchaseHistories />
          </RequireCardRegister>
        ),
        path: "/purchase-histories/:year/:month",
      },
    ],
    element: <Outlet />,
    errorElement: <RootBoundary />,
    path: "/",
  },
];

const AppRoutes: FC = () => {
  return <RouterProvider router={createBrowserRouter(routes)} />;
};

export default AppRoutes;
