import { ActionIcon, Anchor, Flex, Group, Stack, Text } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { ReactComponent as ChevronRightIcon } from "assets/images/icons/chevron_right_blue.svg";
import { ReactComponent as EyeIcon } from "assets/images/icons/eye.svg";
import { ReactComponent as EyeSlashIcon } from "assets/images/icons/eye_slash.svg";
import { SHOW_PURCHASE_AMOUNT_KEY } from "libs/mantine/localStorageKeys";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

type ThisMonthPurchaseAmountProps = {
  purchase_amount: number;
};

const ThisMonthPurchaseAmount: FC<ThisMonthPurchaseAmountProps> = ({
  purchase_amount,
}) => {
  const navigate = useNavigate();

  const [showPurchaseAmount, setShowPurchaseAmount] = useLocalStorage({
    defaultValue: true,
    key: SHOW_PURCHASE_AMOUNT_KEY,
  });

  return (
    <Stack spacing={16}>
      <Flex justify="space-between">
        <Text fw="bold">今月のお買い上げ金額</Text>
        <Anchor
          component="button"
          type="button"
          color="#3078E5"
          onClick={() => navigate("purchase-histories")}
        >
          <Group spacing={4}>
            履歴を見る
            <ChevronRightIcon width={14} height={14} />
          </Group>
        </Anchor>
      </Flex>
      <Flex justify="center" align="center" gap={10}>
        <Text fz={32} fw="bold">
          {showPurchaseAmount
            ? new Intl.NumberFormat().format(purchase_amount)
            : "---,---"}
          <Text component="span" fz="md">
            円
          </Text>
        </Text>
        <ActionIcon
          aria-label="目アイコン"
          onClick={() => setShowPurchaseAmount(!showPurchaseAmount)}
        >
          {showPurchaseAmount ? <EyeIcon /> : <EyeSlashIcon />}
        </ActionIcon>
      </Flex>
      <Stack fw={400} spacing={6}>
        <Text size="sm">最新の情報に更新されるまで時間がかかります。</Text>
        <Text size="sm">
          いちやまマート（スーパーマーケット）でのお買物金額のみ反映されます。
        </Text>
      </Stack>
    </Stack>
  );
};

export default ThisMonthPurchaseAmount;
