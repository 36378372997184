import { Box, List, Text } from "@mantine/core";
import dayjs from "dayjs";
import type { FC } from "react";

type DailyHistoryLabelProps = {
  purchase_amount: number;
  purchased_at: string;
  store_name: string;
};
const numberFormatter = new Intl.NumberFormat();
const DailyHistoryItem: FC<DailyHistoryLabelProps> = ({
  purchase_amount,
  purchased_at,
  store_name,
}) => {
  const formattedDate = dayjs(purchased_at).format("M月D日");
  return (
    <List.Item sx={{ borderBottom: "solid 1px #E0E0E0", padding: "8px 0" }}>
      <Box>
        <Text weight={500} size={14} component="time">
          {`${formattedDate} ${dayjs(purchased_at).format("HH:mm")}`}
        </Text>
        <Text weight={400} size={12} color="#9E9E9E">
          {store_name}
        </Text>
      </Box>
      <Box>
        <Text component="span" size={16} weight={500}>
          {numberFormatter.format(purchase_amount)}
        </Text>
        <Text component="span" size={14} weight={400}>
          円
        </Text>
      </Box>
    </List.Item>
  );
};

export default DailyHistoryItem;
