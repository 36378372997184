import { Box, Center, Image } from "@mantine/core";
import more_card from "assets/images/more_card.png";
import type { FC } from "react";
import Barcode from "react-barcode";

type UserBarcodeCardProps = {
  card_number: string;
};

/** 会員バーコードカード */
const UserBarcodeCard: FC<UserBarcodeCardProps> = ({ card_number }) => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {/* 背景画像 */}
      <Image src={more_card} alt="モアカード" />
      {/* バーコード */}
      <Center
        w="90%"
        sx={{
          backgroundColor: "white",
          borderRadius: "8px",
          bottom: "10%",
          left: "5%",
          position: "absolute",
        }}
      >
        <Barcode
          value={card_number}
          format="CODE128"
          height={70}
          font="Noto Sans JP"
          fontSize={14}
        />
      </Center>
    </Box>
  );
};

export default UserBarcodeCard;
