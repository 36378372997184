import { MantineProvider } from "@mantine/core";
import { useSetAtom } from "jotai";
import { utmRequestParamsAtom } from "libs/jotai/atoms";
import { theme } from "libs/mantine";
import type { FC } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useEffectOnce, useSearchParam } from "react-use";
import AppRoutes from "router";
import { SWRConfig } from "swr";

const App: FC = () => {
  const utmCampaign = useSearchParam("utm_campaign");
  const utmContent = useSearchParam("utm_content");
  const utmMedium = useSearchParam("utm_medium");
  const utmSource = useSearchParam("utm_source");
  const setUtmRequestParams = useSetAtom(utmRequestParamsAtom);

  useEffectOnce(() => {
    setUtmRequestParams({
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_medium: utmMedium,
      utm_source: utmSource,
    });
  });

  return (
    <HelmetProvider>
      <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
        <SWRConfig
          // API の接続回数を減らすため不要な自動再検証を無効にする
          value={{ revalidateOnFocus: false, revalidateOnReconnect: false }}
        >
          <AppRoutes />
        </SWRConfig>
      </MantineProvider>
    </HelmetProvider>
  );
};

export default App;
