import {
  useCreateMyStore,
  useDeleteMyStore,
  useGetMyStores,
} from "features/myStores/api";
import type { MouseEventHandler } from "react";
import { useCallback } from "react";

const useMyStoreMethods = () => {
  const { trigger: createTrigger, isMutating: isCreating } = useCreateMyStore();
  const { trigger: deleteTrigger, isMutating: isDeleting } = useDeleteMyStore();
  const { data, isValidating, mutate } = useGetMyStores();
  const isMyStore = useCallback(
    (id: number) => {
      if (data) {
        return data.stores.map((store) => store.id).includes(id);
      }
      return false;
    },
    [data]
  );
  const onSuccessHandler = useCallback(
    (onSuccess?: VoidFunction) => () => {
      void mutate();
      if (onSuccess) {
        onSuccess();
      }
    },
    [mutate]
  );

  return {
    editMyStoreCreator: useCallback<
      (
        id: number,
        onSuccess?: VoidFunction,
        onFailed?: VoidFunction
      ) => MouseEventHandler
    >(
      (id, onSuccess, onFailed) => async () => {
        if (isMyStore(id)) {
          await deleteTrigger(
            { storeId: id },
            {
              onError: onFailed,
              onSuccess: onSuccessHandler(onSuccess),
            }
          );
        } else {
          await createTrigger(
            { storeId: id },
            {
              onError: onFailed,
              onSuccess: onSuccessHandler(onSuccess),
            }
          );
        }
      },
      [isMyStore, createTrigger, deleteTrigger, onSuccessHandler]
    ),
    isMyStore,
    isValidating: isValidating || isCreating || isDeleting,
  };
};
export default useMyStoreMethods;
