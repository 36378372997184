import {
  Button,
  Divider,
  Flex,
  Paper,
  RingProgress,
  Stack,
  Text,
  rem,
} from "@mantine/core";
import { ReactComponent as ArrowClockwiseIcon } from "assets/images/icons/arrow_clockwise.svg";
import { ReactComponent as CheckIcon } from "assets/images/icons/check.svg";
import { ReactComponent as CheckDisabledIcon } from "assets/images/icons/check_disabled.svg";
import { ReactComponent as ServiceTicketIcon } from "assets/images/icons/service_ticket.svg";
import dayjs from "dayjs";
import ThisMonthPurchaseAmount from "features/purchaseHistories/components/ThisMonthPurchaseAmount";
import type { FC } from "react";
import { useState } from "react";
import type { GetPointBalanceResponseData } from "types/api";

type UserPointBalanceCardProps = Omit<
  GetPointBalanceResponseData,
  "card_number"
> & {
  onMutate: () => Promise<void>;
  purchase_amount: number;
};

/** ポイント残高（取得済クーポン・保有ポイント・サービス券）及びお買上げ金額表示カード */
const UserPointBalanceCard: FC<UserPointBalanceCardProps> = (props) => {
  const [timeStamp, setTimeStamp] = useState<string>(
    dayjs().format("YYYY/MM/DD HH:mm")
  );

  const pointButtonClickHandler = () => {
    void props
      .onMutate()
      .then(() => setTimeStamp(dayjs().format("YYYY/MM/DD HH:mm")));
  };

  return (
    <Paper radius="md" p="md" sx={{ backgroundColor: "#F5F5F5" }}>
      <Stack>
        {/* 更新日時 */}
        <Flex justify="end" align="center" gap={16}>
          <Text fz="sm" c="dimmed">
            {timeStamp} 時点
          </Text>
          <Button
            variant="default"
            size="xs"
            fz="sm"
            fw="bold"
            leftIcon={<ArrowClockwiseIcon />}
            c="#616161"
            bg="#EEEEEE"
            styles={{
              leftIcon: { marginRight: rem(4) },
              root: { border: "1px solid #F2F2F2" },
            }}
            onClick={pointButtonClickHandler}
          >
            更新
          </Button>
        </Flex>
        {/* 今月のお買い上げ金額 */}
        <ThisMonthPurchaseAmount purchase_amount={props.purchase_amount} />
        {/* 取得済みクーポン券 */}
        <Paper radius="md" p="sm" sx={{ border: "1px solid #00ac97" }}>
          <Stack spacing={12}>
            <Text fw="bold">取得済みクーポン券</Text>
            <Flex gap={16}>
              <Flex align="center" gap={4}>
                {props.has_this_month_coupon ? (
                  <CheckIcon />
                ) : (
                  <CheckDisabledIcon />
                )}
                <Text c={props.has_this_month_coupon ? "brand" : "dimmed"}>
                  今月
                </Text>
              </Flex>
              <Flex align="center" gap={4}>
                {props.has_next_month_coupon ? (
                  <CheckIcon />
                ) : (
                  <CheckDisabledIcon />
                )}
                <Text c={props.has_next_month_coupon ? "brand" : "dimmed"}>
                  来月
                </Text>
              </Flex>
            </Flex>
          </Stack>
        </Paper>
        {/* 罫線 */}
        <Divider my={8} />
        {/* 保有ポイント・サービス券 */}
        <Flex>
          {/* 保有ポイント */}
          <Stack w="50%">
            <Text ta="center" fw="bold">
              保有ポイント
            </Text>
            <Flex h={52} align="center" gap={8}>
              <RingProgress
                size={50}
                rootColor="rgba(0, 172, 151, 0.2)"
                thickness={6}
                roundCaps
                sections={[
                  {
                    color: "brand",
                    value: (props.total_point / 250) * 100,
                  },
                ]}
              />
              <Stack spacing={0}>
                <Text fz={24} fw="bold">
                  {props.total_point}
                  <Text component="span" fz="md">
                    P
                  </Text>
                </Text>
                {/* 250は固定値で、250を超えると自動的にサービス券に変換される */}
                <Text>/ 250P</Text>
              </Stack>
            </Flex>
          </Stack>
          <Divider mt={32} mx={8} orientation="vertical" />
          {/* サービス券 */}
          <Stack w="50%">
            <Text ta="center" fw="bold">
              サービス券
            </Text>
            <Flex h={52} align="center" gap={8}>
              <ServiceTicketIcon />
              <Text fz={24} fw="bold">
                {props.digital_ticket_count}
                <Text component="span" fz="md">
                  枚
                </Text>
              </Text>
            </Flex>
          </Stack>
        </Flex>
      </Stack>
    </Paper>
  );
};

export default UserPointBalanceCard;
