import { createStyles, Notification, rem } from "@mantine/core";
import type { CSSProperties, FC } from "react";

type MyStoreStatusBarProps = {
  statusText?: string;
  style?: CSSProperties;
};

const useStyles = createStyles({
  root: {
    "&::before": {
      content: "none",
    },
    backgroundColor: "#525252E5",
    color: "white",
    height: "37px",
    left: rem(6),
    opacity: 0.9,
    padding: "8px 0",
    position: "fixed",
    top: rem(80),
    width: "calc(100vw - 12px)",
    zIndex: 100,
  },
  title: {
    color: "white",
    fontSize: rem(13),
    fontWeight: 500,
    textAlign: "center",
  },
});
const MyStoreStatusBar: FC<MyStoreStatusBarProps> = ({ statusText, style }) => {
  const { classes } = useStyles();
  return (
    <Notification
      style={style}
      classNames={classes}
      color="dark"
      radius="xs"
      title={statusText}
      withCloseButton={false}
    />
  );
};

export default MyStoreStatusBar;
