import { Box, Button, Text, Title, createStyles, rem } from "@mantine/core";
import { useGetUser } from "api";
import MainLayout from "components/layouts/MainLayout";
import { useAtomValue } from "jotai";
import { PAGE_TITLE } from "libs/constants";
import { utmRequestParamsAtom } from "libs/jotai/atoms";
import type { FC } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import type {
  PostCardRequestData,
  PostCardReregisterRequestData,
  PostCardReregisterResponseData,
  PostCardResponseData,
  UtmRequestParams,
} from "types/api";
import type { SignupForm } from "types/form";

const useStyles = createStyles(() => ({
  box: {
    borderBottom: "1px solid #e0e0e0",
    paddingBottom: rem(8),
    paddingTop: rem(8),
  },
  label: {
    fontSize: rem(12),
    fontWeight: 700,
    marginBottom: rem(8),
  },
  text: {
    color: "#191919",
    fontSize: rem(14),
  },
}));

type PostCardTrigger = (
  data: PostCardRequestData,
  options?: {
    onError: ({ errors }: { errors: string[] }) => void;
    onSuccess: () => void;
    throwOnError: boolean;
  }
) => Promise<PostCardResponseData>;

type PostCardReregisterTrigger = (
  data: PostCardReregisterRequestData,
  options?: {
    onError: ({ errors }: { errors: string[] }) => void;
    onSuccess: () => void;
    throwOnError: boolean;
  }
) => Promise<PostCardReregisterResponseData>;

type CardRegisterConfirmProps = {
  errorPath: string;
  isMutating: boolean;
  registerButtonPath: string;
  trigger: PostCardTrigger | PostCardReregisterTrigger;
};

const CardRegisterConfirm: FC<CardRegisterConfirmProps> = ({
  errorPath,
  isMutating,
  registerButtonPath,
  trigger,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { mutate, isValidating } = useGetUser();
  const utmRequestParams = useAtomValue<UtmRequestParams>(utmRequestParamsAtom);

  // stateが正しく取得出来ていない場合は、入力画面に戻す
  if (!location.state) return <Navigate to={errorPath} />;

  const { card_number, first_name, last_name } = location.state as SignupForm;

  const postData =
    location.pathname === "/my-page/card-reregister/confirm"
      ? { card_number, first_name, last_name }
      : { card_number, first_name, last_name, ...utmRequestParams };

  const onClickButton = () => {
    void trigger(postData, {
      onError: ({ errors }) => {
        navigate({
          pathname: errorPath,
          search: `?errors=${errors.join("_")}`,
        }); // 入力画面に戻す
      },
      onSuccess: () => {
        // カード登録状態を最新にする
        void mutate().then(() => {
          navigate(location.pathname, { replace: true }); // state を削除
          navigate(registerButtonPath); // 完了画面へ
        });
      },
      throwOnError: false,
    });
  };

  return (
    <MainLayout footer={false} pageTitle={PAGE_TITLE.CARD_REGISTER}>
      <Title ta="center" size={18} fw="700" mb={20} color="#333">
        入力内容を確認してください
      </Title>
      <Box mb={8} className={classes.box}>
        <Text className={classes.label}>カード番号</Text>
        <Text className={classes.text}>{card_number}</Text>
      </Box>
      <Box mb={20} className={classes.box}>
        <Text className={classes.label}>姓名（セイメイ）</Text>
        <Text className={classes.text}>
          {last_name} {first_name}
        </Text>
      </Box>
      <Button
        size="lg"
        radius={5}
        fz={16}
        fw="700"
        fullWidth
        loading={isMutating || isValidating}
        onClick={onClickButton}
      >
        登録する
      </Button>
    </MainLayout>
  );
};

export default CardRegisterConfirm;
