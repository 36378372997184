import type {
  DefaultMantineColor,
  MantineThemeOverride,
  Tuple,
} from "@mantine/core";

export const theme: MantineThemeOverride = {
  colorScheme: "light",
  colors: {
    /**
     * ブランド(brand)カラー
     *
     * メインカラー(#00ac97)をもとに↓で10色作成
     * https://omatsuri.app/color-shades-generator
     */
    brand: [
      "#7db3ad",
      "#64b1a7",
      "#4ab1a5",
      "#38ad9f",
      "#25ab9a",
      "#13aa98",
      "#00ac97", // main color
      "#0f8b7c",
      "#197267",
      "#1f5f57",
    ],
  },
  fontFamily: '"Noto Sans JP", sans-serif',
  fontSizes: {
    /**
     * フォントサイズ定義
     *
     * https://mantine.dev/theming/typography/#font-sizes
     */
    lg: "1rem",
    md: "0.85rem",
    sm: "0.71rem",
    xl: "1.3rem",
  },
  globalStyles: (_) => ({
    "html, body": { height: "100%", overflow: "hidden" },
  }),
  primaryColor: "brand",
  primaryShade: 6,
};

/**
 * カラー型の拡張
 *
 * https://mantine.dev/theming/colors/#add-custom-colors-types
 */
declare module "@mantine/core" {
  export interface MantineThemeColorsOverride {
    colors: Record<"brand" | DefaultMantineColor, Tuple<string, 10>>;
  }
}
