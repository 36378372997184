import { Button, List, Title } from "@mantine/core";
import MainLayout from "components/layouts/MainLayout";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

const CardReregisterTop: FC = () => {
  const navigate = useNavigate();
  return (
    <MainLayout pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
      <Title size={18} mb={24}>
        店舗で再発行したカードを登録する
      </Title>
      <Button
        onClick={() => navigate("/my-page/card-reregister/new")}
        size="lg"
        radius={5}
        fz={16}
        fw="700"
        fullWidth
      >
        カードを登録する
      </Button>
      <List
        icon="※"
        styles={{ itemIcon: { marginRight: 0 }, root: { marginTop: 20 } }}
        mt={20}
      >
        <List.Item>
          店頭でカードを発行した、翌日以降、こちらからカードの登録が可能となります。
        </List.Item>
        <List.Item>
          カードの登録が完了すると、ホーム画面のカード番号が変更され、変更前のカード番号を表示することはできなくなります。
        </List.Item>
      </List>
    </MainLayout>
  );
};
export default CardReregisterTop;
