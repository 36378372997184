// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import type { AxiosError } from "axios";
import axios, { isAxiosError } from "axios";
import { BASE_URL } from "libs/constants";
import { handleMoveToErrorPage } from "libs/handleError";
import type { SWRConfiguration } from "swr";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import type {
  DailyPurchaseHistoriesRequestParams,
  ErrorResponseBody,
  GetDailyPurchaseHistoriesResponseData,
  GetMonthlyPurchaseHistoriesResponseData,
  GetPointBalanceResponseData,
  GetUserResponseData,
  MonthlyPurchaseHistoriesRequestParams,
  PostCardRequestData,
  PostCardReregisterRequestData,
  PostCardReregisterResponseData,
  PostCardResponseData,
  SuccessResponseBody,
} from "types/api";

// axios instance
const instance = axios.create({ baseURL: BASE_URL });
// interceptors request
instance.interceptors.request.use((config) => {
  config.headers.setContentType("application/json; charset=UTF-8");
  config.headers.set("Access-Token", liff.getAccessToken());
  config.headers.set("Token-Id", liff.getIDToken());
  return config;
});
export const apiRejectHandler = (error: AxiosError) => {
  if (!isAxiosError<ErrorResponseBody>(error)) return Promise.reject(error);

  switch (error.response?.status) {
    // 401
    case 401:
      localStorage.clear();
      handleMoveToErrorPage("session-expired");
      break;
    case 404:
      // カード未登録のユーザーがカード登録済みユーザーのみアクセスできる画面にいた場合は初期画面へ
      if (
        error.response.data.errors.includes("カードが登録されていません") &&
        window.location.pathname !== "/signup/top" &&
        window.location.pathname !== "/signup/new" &&
        window.location.pathname !== "/signup/confirm"
      ) {
        window.location.replace(`/signup/top${window.location.search}`);
      }
      break;
    // 500
    case 500: // Internal Server Error
    case 501: // Not Implemented
    case 502: // Bad Gateway
    case 503: // Service Unavailable
    case 504: // Gateway Timeout
    case 505: // HTTP Version Not Supported
    case 506: // Variant Also Negotiates
    case 507: // Insufficient Storage
    case 508: // Loop Detected
    case 510: // Not Extended
    case 511: // Network Authentication Required
      handleMoveToErrorPage("server-error", error.response.status);
      break;
  }
  return Promise.reject(error);
};
// interceptors response
instance.interceptors.response.use((response) => response, apiRejectHandler);

// ---------- GET ----------
const fetcher = <T = unknown>(path: string) =>
  instance
    .get<SuccessResponseBody<T>>(path)
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) =>
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      )
    );

/** API004 ポイント残高取得  */
export const useGetPointBalance = (options: SWRConfiguration = {}) =>
  useSWR<GetPointBalanceResponseData, ErrorResponseBody>(
    "users/current/point_balance",
    fetcher<GetPointBalanceResponseData>,
    options
  );

/** API005 会員情報取得 */
export const useGetUser = (options: SWRConfiguration = {}) =>
  useSWR<GetUserResponseData, ErrorResponseBody>(
    "users/current",
    fetcher<GetUserResponseData>,
    options
  );

/** API006 月ごとの購入履歴 */
export const useGetMonthlyPurchaseHistories = (
  requestParams: MonthlyPurchaseHistoriesRequestParams,
  options: SWRConfiguration = {}
) =>
  useSWR<GetMonthlyPurchaseHistoriesResponseData, ErrorResponseBody>(
    `users/current/purchase_histories/monthly?start_month=${requestParams.startMonth}&end_month=${requestParams.endMonth}`,
    fetcher<GetMonthlyPurchaseHistoriesResponseData>,
    options
  );

/** API007 日ごとの購入履歴 */
export const useGetDailyPurchaseHistories = (
  // 500は先方指定した数
  { startDate, endDate, limit = 500 }: DailyPurchaseHistoriesRequestParams,
  options: SWRConfiguration = {}
) =>
  useSWR<GetDailyPurchaseHistoriesResponseData, ErrorResponseBody>(
    `users/current/purchase_histories/daily?start_date=${startDate}&end_date=${endDate}&limit=${limit}`,
    fetcher<GetDailyPurchaseHistoriesResponseData>,
    options
  );

// ---------- POST ----------
const fetcherForPost = <T = unknown>(path: string, { arg }: { arg: object }) =>
  instance
    .post<SuccessResponseBody<T>>(path, arg)
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) =>
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      )
    );

/** API001 カード連携 */
export const usePostCard = () =>
  useSWRMutation<
    PostCardResponseData, // data
    ErrorResponseBody, // error
    string, // key
    PostCardRequestData // リクエストボディ
  >("/users/current/card", fetcherForPost);

/** API003 カード再連携 */
export const usePostCardReregister = () =>
  useSWRMutation<
    PostCardReregisterResponseData, // data
    ErrorResponseBody, // error
    string, // key
    PostCardReregisterRequestData // リクエストボディ
  >("/users/current/card/reregister", fetcherForPost);
