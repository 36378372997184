import {
  Box,
  Button,
  Flex,
  Text,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import { hasLength, matches, useForm } from "@mantine/form";
import { ReactComponent as WarningIcon } from "assets/images/icons/warning.svg";
import MainLayout from "components/layouts/MainLayout";
import CustomTextInput from "components/modules/CustomTextInput";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { useNavigate, useSearchParams, type To } from "react-router-dom";
import type { SignupForm } from "types/form";

// 汎用的に使用するスタイルの設定
const useStyles = createStyles(() => ({
  text: {
    color: "#9e9e9e",
    fontSize: rem(12),
  },
}));

type CardRegisterNewProps = {
  confirmationButtonPath: To; // 「次へ」ボタン クリック時の遷移先
};

const CardRegisterNew: FC<CardRegisterNewProps> = ({
  confirmationButtonPath,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const postCardResponseErrorsStr = searchParams.get("errors");
  const postCardResponseErrors = postCardResponseErrorsStr?.split("_");

  // バリデーション
  const form = useForm<SignupForm>({
    // バリデーション対象の初期値設定
    initialValues: { card_number: "", first_name: "", last_name: "" },
    // バリデーション設定
    validate: {
      card_number: matches(/^\d{14}$/, "14桁のカード番号をご入力ください。"),
      first_name: (value) => {
        const textValidateResult = matches(
          /^[ァ-ヺー－]+$/u,
          "「名（メイ）」は全角カタカナ30文字以内で入力してください。"
        )(value);
        const lengthValidateResult = hasLength(
          { max: 30 },
          "「名（メイ）」は全角カタカナ30文字以内で入力してください。"
        )(value);
        return lengthValidateResult || textValidateResult;
      },
      last_name: (value) => {
        const textValidateResult = matches(
          /^[ァ-ヺー－]+$/u,
          "「姓（セイ）」は全角カタカナ30文字以内で入力してください。"
        )(value);
        const lengthValidateResult = hasLength(
          { max: 30 },
          "「姓（セイ）」は全角カタカナ30文字以内で入力してください。"
        )(value);
        return lengthValidateResult || textValidateResult;
      },
    },
    // バリデーションの発動点の設定
    validateInputOnBlur: ["card_number", "first_name", "last_name"],
  });

  return (
    <MainLayout footer={false} pageTitle={PAGE_TITLE.CARD_REGISTER}>
      <Title ta="center" size={18} fw="700" mb={20} color="#333">
        カード情報入力
      </Title>
      {/* 確認画面 でAPIエラーが発生し、リダイレクトされた際のエラーメッセージ */}
      {postCardResponseErrors && (
        <Flex
          mb={24}
          p={8}
          align="center"
          gap={8}
          sx={{
            background: "rgba(234, 91, 94, 0.1)",
            borderLeft: "2px solid #ea5b5e",
          }}
        >
          <WarningIcon width={16} height={16} />
          <Box>
            {postCardResponseErrors.map((error, key) => (
              <Text key={key} fz="md" c="#ea5b5e">
                {error}
              </Text>
            ))}
          </Box>
        </Flex>
      )}
      <form
        onSubmit={form.onSubmit((values) =>
          navigate(confirmationButtonPath, {
            state: values,
          })
        )}
      >
        <CustomTextInput
          label="カード番号"
          placeholder="例：00000000000000"
          mb={8}
          {...form.getInputProps("card_number")}
        />
        <Box mb={20}>
          <Text className={classes.text}>
            ※モアカード表面にある14桁の番号をハイフンなしでご入力ください。
          </Text>
        </Box>
        <Flex justify="space-between" gap={8} mb={8}>
          <CustomTextInput
            w="100%"
            label="姓（セイ）"
            placeholder="シンキ"
            {...form.getInputProps("last_name")}
          />
          <CustomTextInput
            w="100%"
            label="名（メイ）"
            placeholder="トウロク"
            {...form.getInputProps("first_name")}
          />
        </Flex>
        <Box mb={20}>
          <Text className={classes.text}>
            ※カード発行時に登録いただいたお名前をカタカナでご入力ください。
          </Text>
          <Text className={classes.text}>
            ※入力情報は本人確認のために使用し、その他理由では使用しません。
          </Text>
        </Box>
        <Button
          type="submit"
          size="lg"
          radius={5}
          fz={16}
          fw="700"
          fullWidth
          disabled={!form.isValid()}
          sx={{
            "&[data-disabled]": { background: "#e0e0e0", color: "#bdbdbd" },
          }}
        >
          次へ
        </Button>
      </form>
    </MainLayout>
  );
};

export default CardRegisterNew;
