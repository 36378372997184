import CardRegisterNew from "features/user/components/cardRegister/CardRegisterNew";
import type { FC } from "react";

const CardReregisterNew: FC = () => {
  return (
    <CardRegisterNew confirmationButtonPath="/my-page/card-reregister/confirm" />
  );
};

export default CardReregisterNew;
