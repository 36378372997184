import { Box, List, Text } from "@mantine/core";
import type { FC, ReactNode } from "react";

type YearBoxProps = {
  children: ReactNode;
  useList?: boolean;
  yearLabel: string;
};

const YearBox: FC<YearBoxProps> = ({ children, yearLabel, useList }) => (
  <Box mb={10}>
    <Text weight={700} size={12} color="#9E9E9E">
      {yearLabel}
    </Text>
    {useList ? (
      <List
        listStyleType="none"
        styles={{
          itemWrapper: {
            "&> span": {
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            },
            display: "block",
          },
        }}
      >
        {children}
      </List>
    ) : (
      <>{children}</>
    )}
  </Box>
);
export default YearBox;
