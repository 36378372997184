import { Button, Title } from "@mantine/core";
import MainLayout from "components/layouts/MainLayout";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

const CardRegisterComplete: FC = () => {
  const navigate = useNavigate();

  return (
    <MainLayout footer={false} pageTitle={PAGE_TITLE.CARD_REGISTER}>
      <Title size={18} fw="700" ta="center" mb={20} color="#333">
        カードの登録が完了しました
      </Title>
      <Button
        size="lg"
        fz={16}
        fw="700"
        radius={5}
        fullWidth
        onClick={() => navigate("/")}
      >
        ホームへ
      </Button>
    </MainLayout>
  );
};

export default CardRegisterComplete;
