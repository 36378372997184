import {
  Footer as MantineFooter,
  NavLink,
  Paper,
  UnstyledButton,
  rem,
} from "@mantine/core";
import { Fragment, type FC, type ReactNode } from "react";

import { ReactComponent as HomeIcon } from "assets/images/icons/home.svg";
import { ReactComponent as HomeActiveIcon } from "assets/images/icons/home_active.svg";
import { ReactComponent as MagazineIcon } from "assets/images/icons/magazine.svg";
import { ReactComponent as MyPageIcon } from "assets/images/icons/my_page.svg";
import { ReactComponent as MyPageActiveIcon } from "assets/images/icons/my_page_active.svg";
import { ReactComponent as MyStoreIcon } from "assets/images/icons/my_store.svg";
import { ReactComponent as MyStoreActiveIcon } from "assets/images/icons/my_store_active.svg";
// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { Link, matchPath, useLocation } from "react-router-dom";

const FOOTER_ITEMS: {
  activeIcon?: ReactNode;
  end: boolean;
  icon: ReactNode;
  isExternal?: boolean;
  label: string;
  otherMatchPaths?: { end: boolean; path: string }[];
  path: string;
}[] = [
  {
    activeIcon: <HomeActiveIcon />,
    end: true,
    icon: <HomeIcon />,
    label: "ホーム",
    otherMatchPaths: [
      {
        end: false,
        path: "/purchase-histories",
      },
    ],
    path: "/",
  },
  {
    activeIcon: <MyStoreActiveIcon />,
    end: false,
    icon: <MyStoreIcon />,
    label: "マイ店舗",
    path: "/my-stores",
  },
  {
    end: true,
    icon: <MagazineIcon />,
    isExternal: true,
    label: "美味マガジン",
    path: "https://ichiyamamart.com/magazine",
  },
  {
    activeIcon: <MyPageActiveIcon />,
    end: false,
    icon: <MyPageIcon />,
    label: "マイページ",
    path: "/my-page",
  },
];

const Footer: FC = () => {
  const { pathname } = useLocation();
  return (
    <MantineFooter height={64}>
      <Paper shadow="0 -2px 10px #e2e2e2" h={64} display="flex">
        {FOOTER_ITEMS.map(
          (
            { activeIcon, end, icon, isExternal, label, path, otherMatchPaths },
            index
          ) => {
            const isMatchPath =
              !!matchPath({ end, path }, pathname) ||
              (otherMatchPaths &&
                otherMatchPaths.some((pathSetting) =>
                  matchPath(
                    { end: pathSetting.end, path: pathSetting.path },
                    pathname
                  )
                ));
            const setCurrentIcon =
              isMatchPath && activeIcon ? activeIcon : icon;

            return (
              <Fragment key={index}>
                {isExternal ? (
                  <UnstyledButton
                    w="100%"
                    px={12}
                    py={6}
                    display="flex"
                    ta="center"
                    fz={10}
                    fw={500}
                    c="#000"
                    sx={{
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      rowGap: 2,
                    }}
                    onClick={() =>
                      liff.openWindow({ external: false, url: path })
                    }
                  >
                    {icon}
                    {label}
                  </UnstyledButton>
                ) : (
                  <NavLink
                    active={isMatchPath}
                    variant="subtle"
                    icon={setCurrentIcon}
                    label={label}
                    component={Link}
                    to={path}
                    c="#000"
                    color="brand"
                    ta="center"
                    styles={(theme) => ({
                      body: { flex: "initial", lineHeight: rem(1) },
                      icon: { marginRight: 0 },
                      label: {
                        fontSize: rem(10),
                        fontWeight: 500,
                      },
                      root: {
                        ":hover[data-active]": {
                          backgroundColor: "#fff",
                        },
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        rowGap: rem(2),
                      },
                    })}
                  />
                )}
              </Fragment>
            );
          }
        )}
      </Paper>
    </MantineFooter>
  );
};

export default Footer;
