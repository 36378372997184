import type { ErrorCode, ErrorTexts } from "types/error";

/**
 * エラーコードを付与してエラーページへ遷移させる
 * @param {ErrorCode} code
 * @param {number} [httpStatusCode]
 */
// eslint-disable-next-line import/no-named-export
export const handleMoveToErrorPage = (
  code: ErrorCode,
  httpStatusCode?: number
) => {
  window.location.replace(
    `/error?error_code=${code}&http_status_code=${httpStatusCode ?? ""}`
  );
};

/**
 * エラーページに表示するタイトルと説明文を取得
 * @param {string} paramStr
 * @return {ErrorTexts}
 */
// eslint-disable-next-line import/no-named-export
export const getErrorTexts = (paramStr: string): ErrorTexts => {
  const searchParams = new URLSearchParams(paramStr);
  const errorCode = searchParams.get("error_code");
  const httpStatusCode = searchParams.get("http_status_code");

  switch (errorCode) {
    case "server-error":
      return {
        description: (
          <>
            ご迷惑をおかけいたしますが、
            <br />
            時間をおいてからもう一度ご利用をお試しください。
            <br />
            なお、午前1時から午前8時の間は、
            <br />
            システムメンテナンスを行っております。
            <br />
            午前8時以降にご利用をお試しください。
            <br />
            {`${
              httpStatusCode
                ? `（server-error, status: ${httpStatusCode}）`
                : ""
            }`}
          </>
        ),
        title: "現在サービスをご利用いただけません",
      };
    case "session-expired":
      return {
        description: (
          <>
            セッションの有効期限が切れています。
            <br />
            アプリを再起動してください。
          </>
        ),
        title: "セッションの有効期限切れ",
      };
    case "init-liff-failure":
      return {
        description: "アプリの起動に失敗しました。",
        title: "起動失敗",
      };
    default:
      return {
        description: "エラーが発生しました。",
        title: "エラー",
      };
  }
};
