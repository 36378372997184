import { Center, Image, Header as MantineHeader, Paper } from "@mantine/core";
import Logo from "assets/images/icons/logo.svg";
import type { FC } from "react";
import { Link } from "react-router-dom";

const Header: FC = () => {
  return (
    <MantineHeader height={64} withBorder={false}>
      <Paper shadow="0px 10px 10px rgba(0, 0, 0, 0.1)" pos="relative" h={45}>
        <Center
          component={Link}
          to="/"
          bg="#fff"
          h={64}
          m="auto"
          w={64}
          sx={{
            borderRadius: "50%",
          }}
        >
          <Image src={Logo} alt="ICHIYAMA" width={32} height={32} />
        </Center>
      </Paper>
    </MantineHeader>
  );
};

export default Header;
