import { Button, Stack, Text, Title, Transition } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import { ReactComponent as CaretRightIcon } from "assets/images/icons/caret_right.svg";
import { ReactComponent as MagnifyingGlassIcon } from "assets/images/icons/magnifying_glass.svg";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import MyStoreStatusBar from "components/modules/MyStoreStatusBar";
import { useGetMyStores } from "features/myStores/api";

import StoreCard from "features/myStores/components/StoreCard";
import useMyStoreMethods from "features/myStores/hooks/useMyStoreMethods";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

const MyStores: FC = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetMyStores();
  const {
    isMyStore,
    isValidating: isMutating,
    editMyStoreCreator,
  } = useMyStoreMethods();
  const [statusText, setStatusText] = useState<undefined | string>(undefined);
  const { start, clear } = useTimeout(() => setStatusText(undefined), 1000);
  const onSuccessHandler = useCallback(
    (id: number) => () => {
      if (isMyStore(id)) {
        setStatusText("マイ店舗から削除しました");
      } else {
        setStatusText("マイ店舗に登録しました");
      }
      clear();
      start();
    },
    [start, clear, isMyStore]
  );

  if (!data || isLoading) return <Loading />;

  return (
    <MainLayout pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
      <Transition
        transition={{
          in: { opacity: 0.9 },
          out: { opacity: 0 },
          transitionProperty: "transform, opacity",
        }}
        mounted={Boolean(statusText)}
      >
        {(style) => <MyStoreStatusBar statusText={statusText} style={style} />}
      </Transition>
      <Stack spacing={24}>
        <Title size={18}>マイ店舗</Title>
        {data?.stores.length ? (
          <Stack>
            {data.stores.map((store, index) => (
              <StoreCard
                isMyStore
                isMutating={isMutating}
                store={store}
                key={`store_${index}`}
                onClickMyStoreButton={editMyStoreCreator(
                  store.id,
                  onSuccessHandler(store.id)
                )}
              />
            ))}
          </Stack>
        ) : (
          <Text color="#9E9E9E" align="center" size={16} weight={700}>
            マイ店舗は登録されていません
          </Text>
        )}
        <Button
          onClick={() => navigate("/my-stores/edit")}
          size="lg"
          radius={5}
          fz={16}
          fw="700"
          fullWidth
        >
          {data?.stores.length ? (
            <>
              <MagnifyingGlassIcon style={{ marginRight: "5px" }} />
              他の店舗を探す
            </>
          ) : (
            <>
              マイ店舗を登録する
              <CaretRightIcon style={{ marginLeft: "5px" }} />
            </>
          )}
        </Button>
      </Stack>
    </MainLayout>
  );
};

export default MyStores;
