// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { Button, Stack, rem } from "@mantine/core";
import { useGetMonthlyPurchaseHistories, useGetPointBalance } from "api";
import { ReactComponent as ChevronRightIcon } from "assets/images/icons/chevron_right.svg";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import dayjs from "dayjs";
import UserBarcodeCard from "features/user/components/UserBarcodeCard";
import UserPointBalanceCard from "features/user/components/UserPointBalanceCard";
import { PAGE_TITLE } from "libs/constants";
import { useMemo, type FC } from "react";

const Home: FC = () => {
  const { data, isValidating, mutate } = useGetPointBalance();

  // 自動テストだと「更新」ボタン クリック時、API006 月ごとの購入履歴 が2回呼び出されてしまうので、メモ化する
  // https://github.com/voyagegroup/digiclue-ichiyamamart-front/pull/63
  const currentMonth = useMemo(() => dayjs().format("YYYY-MM"), []);
  const {
    data: monthlyPurchaseHistoriesData,
    isValidating: isValidatingMonthlyPurchaseHistories,
    mutate: mutateMonthlyPurchaseHistories,
  } = useGetMonthlyPurchaseHistories({
    endMonth: currentMonth,
    startMonth: currentMonth,
  });

  const onMutate = async () => {
    await mutate();
    await mutateMonthlyPurchaseHistories();
  };

  if (
    isValidating ||
    !data ||
    isValidatingMonthlyPurchaseHistories ||
    !monthlyPurchaseHistoriesData
  )
    return <Loading pageTitle={PAGE_TITLE.ICHIYAMA_MART} />;

  const { card_number, ...rest } = data;

  return (
    <MainLayout header footer pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
      <Stack spacing={24}>
        {/* 会員バーコードカード */}
        <UserBarcodeCard card_number={card_number} />
        {/* ポイント残高（取得済クーポン・保有ポイント・サービス券）及びお買上げ金額表示カード */}
        <UserPointBalanceCard
          {...rest}
          onMutate={onMutate}
          purchase_amount={
            monthlyPurchaseHistoriesData.purchase_histories[0].purchase_amount
          }
        />
        {/* 「クーポン券対象商品」ボタン */}
        <Button
          variant="outline"
          size="lg"
          rightIcon={<ChevronRightIcon />}
          fw={700}
          sx={{ borderWidth: rem(2) }}
          styles={{ rightIcon: { marginLeft: rem(4) } }}
          onClick={() =>
            liff.openWindow({
              external: false,
              url: "https://ichiyamamart.com/bimimagazine/coupon-point",
            })
          }
        >
          クーポン券対象商品
        </Button>
        {/* 「サービス券カタログ」ボタン */}
        <Button
          variant="outline"
          size="lg"
          rightIcon={<ChevronRightIcon />}
          fw={700}
          sx={{ borderWidth: rem(2) }}
          styles={{ rightIcon: { marginLeft: rem(4) } }}
          onClick={() =>
            liff.openWindow({
              external: false,
              url: "https://ichiyamamart.com/bimimagazine/30yen-sale-change",
            })
          }
        >
          サービス券カタログ
        </Button>
      </Stack>
    </MainLayout>
  );
};

export default Home;
