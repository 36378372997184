import {
  Box,
  Button,
  Card,
  List,
  Stack,
  Text,
  createStyles,
  rem,
} from "@mantine/core";
import { ReactComponent as ClockIcon } from "assets/images/icons/clock.svg";
import { ReactComponent as LinkIcon } from "assets/images/icons/link_simple.svg";
import { ReactComponent as TelIcon } from "assets/images/icons/phone.svg";
import type { Store } from "features/myStores/types";
import type { FC, MouseEventHandler } from "react";
// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";

const useStyles = createStyles(() => ({
  list: {
    color: "#757575",
    display: "flex",
    flexDirection: "column",
    fontWeight: 400,
    gap: rem(4),
  },
}));
const useButtonStyles = createStyles(() => ({
  inner: {
    justifyContent: "flex-start",
  },
  label: {
    color: "#3078E5",
    fontSize: rem(14),
    fontWeight: 400,
    textAlign: "left",
  },
  root: {
    backgroundColor: "transparent",
    display: "block",
    height: rem(28),
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

type StoreCardProps = {
  isMutating: boolean;
  isMyStore?: boolean;
  onClickMyStoreButton?: MouseEventHandler;
  store: Store;
};

const StoreCard: FC<StoreCardProps> = ({
  store: { address, business_hour, name, postal_code, tel, external_links, id },
  isMyStore,
  onClickMyStoreButton,
  isMutating = false,
}) => {
  const { classes } = useStyles();
  const { classes: buttonClasses } = useButtonStyles();

  return (
    <Card padding="lg" radius="md" withBorder role="listitem" component="li">
      <Stack spacing={24}>
        <Box>
          <Stack spacing={8} mb={16}>
            <Text weight={700} size={16}>
              {name}
            </Text>
            <Text weight={400} size={12}>
              {`${postal_code}${address}`}
            </Text>
          </Stack>
          <List center className={classes.list} size={12} spacing={4}>
            <List.Item icon={<ClockIcon />}>{business_hour || "-"}</List.Item>
            <List.Item icon={<TelIcon />}>{tel || "-"}</List.Item>
          </List>
        </Box>
        {Boolean(external_links?.length) && external_links && (
          <Stack spacing={0}>
            {external_links.map(({ label, url }, index) => (
              <Button
                key={`external_link-${id}-${index}`}
                variant="subtle"
                leftIcon={<LinkIcon />}
                classNames={buttonClasses}
                onClick={() => {
                  liff.openWindow({
                    external: false,
                    url,
                  });
                }}
              >
                {label}
              </Button>
            ))}
          </Stack>
        )}
        <Button
          onClick={onClickMyStoreButton}
          loading={isMutating}
          variant="outline"
          styles={{
            label: {
              color: isMyStore ? "#9E9E9E" : "#00AC97",
            },
            root: {
              borderColor: isMyStore ? "#9E9E9E" : "#00AC97",
            },
          }}
        >
          {isMyStore ? "マイ店舗から削除する" : "マイ店舗に登録する"}
        </Button>
      </Stack>
    </Card>
  );
};
export default StoreCard;
