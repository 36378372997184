import {
  TextInput,
  createStyles,
  rem,
  type TextInputProps,
} from "@mantine/core";
import type { FC } from "react";

const useStyles = createStyles(() => ({
  input: {
    "&[data-invalid]": {
      background: "rgba(234, 91, 94, 0.1)",
      borderColor: "#ea5b5e",
      color: "#ea5b5e",
    },
    "::placeholder": {
      color: "#bdbdbd",
    },
    borderColor: "#e0e0e0",
    borderRadius: rem(6),
    fontSize: rem(14),
    height: rem(46),
  },
  label: {
    fontSize: rem(12),
    fontWeight: 700,
  },
  validError: {
    color: "#ea5b5e",
    fontSize: rem(12),
  },
}));

const CustomTextInput: FC<TextInputProps> = (props) => {
  const { classes } = useStyles();

  return (
    <TextInput
      classNames={{
        error: classes.validError,
        input: classes.input,
        label: classes.label,
      }}
      {...props}
    />
  );
};

export default CustomTextInput;
