// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { apiRejectHandler } from "api";
import axios, { isAxiosError } from "axios";
import type { GetStoresResponseData } from "features/myStores/types";
import { FEATURES_API_ENDPOINT } from "libs/constants";
import type { SWRConfiguration } from "swr";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import type { ErrorResponseBody, SuccessResponseBody } from "types/api";

const storeApiInstance = axios.create({
  baseURL: `${FEATURES_API_ENDPOINT}/store`,
});

storeApiInstance.interceptors.request.use((config) => {
  config.headers.setContentType("application/json; charset=UTF-8");
  config.headers.set("Access-Token", liff.getAccessToken());
  config.headers.set("Token-Id", liff.getIDToken());
  return config;
});

storeApiInstance.interceptors.response.use(
  (response) => response,
  apiRejectHandler
);

// ---------- GET ----------
const fetcher = <T = unknown>(path: string) =>
  storeApiInstance
    .get<SuccessResponseBody<T>>(path)
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) =>
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      )
    );

/** Package 全店舗取得  */
export const useGetAllStores = (options: SWRConfiguration = {}) =>
  useSWR<GetStoresResponseData, ErrorResponseBody>(
    "stores",
    fetcher<GetStoresResponseData>,
    options
  );
/** Package マイ店舗取得  */
export const useGetMyStores = (options: SWRConfiguration = {}) =>
  useSWR<GetStoresResponseData, ErrorResponseBody>(
    "my_stores",
    fetcher<GetStoresResponseData>,
    options
  );

// ---------- POST ----------
const fetcherForPost = <T = unknown>(
  path: string,
  {
    arg: { storeId },
  }: {
    arg: {
      storeId: number;
    };
  }
) =>
  storeApiInstance
    .post<SuccessResponseBody<T>>(
      `${path.replace(":storeId", String(storeId))}`
    )
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) =>
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      )
    );

/** Package マイ店舗作成 */
export const useCreateMyStore = () =>
  useSWRMutation<
    SuccessResponseBody,
    ErrorResponseBody,
    string,
    { storeId: number }
  >("/my_stores/:storeId", fetcherForPost);
// export const useCreateMyStore = () => {
//   const [isMutating, setIsMutating] = useState(false);
//   return {
//     createMyStoreCreator: useCallback<
//       (
//         id: number,
//         onSuccess?: VoidFunction,
//         onFailed?: VoidFunction
//       ) => MouseEventHandler
//     >(
//       (id, onSuccess, onFailed) => async () => {
//         setIsMutating(true);
//         await fetcherForPost(`/my_stores/${id}`, {})
//           .then(async () => {
//             await mutate("my_stores").catch(() => {
//               if (onFailed) {
//                 onFailed();
//               }
//             });
//             if (onSuccess) {
//               onSuccess();
//             }
//             setIsMutating(false);
//           })
//           .catch(() => {
//             if (onFailed) {
//               onFailed();
//             }
//             setIsMutating(false);
//           });
//       },
//       []
//     ),
//     isMutating,
//   };
// };

// ---------- DELETE ----------
const fetcherForDelete = <T = unknown>(
  path: string,
  {
    arg: { storeId },
  }: {
    arg: {
      storeId: number;
    };
  }
) =>
  storeApiInstance
    .delete<SuccessResponseBody<T>>(
      `${path.replace(":storeId", String(storeId))}`
    )
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) =>
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      )
    );

/** Package マイ店舗削除 */
export const useDeleteMyStore = () =>
  useSWRMutation<
    SuccessResponseBody,
    ErrorResponseBody,
    string,
    { storeId: number }
  >("/my_stores/:storeId", fetcherForDelete);
// export const useDeleteMyStore = () => {
//   const [isMutating, setIsMutating] = useState(false);
//   return {
//     deleteMyStoreCreator: useCallback<
//       (
//         id: number,
//         onSuccess?: VoidFunction,
//         onFailed?: VoidFunction
//       ) => MouseEventHandler
//     >(
//       (id, onSuccess, onFailed) => async () => {
//         setIsMutating(true);
//         await storeApiInstance
//           .delete(`/my_stores/${id}`)
//           .then(async () => {
//             await mutate("my_stores").catch(() => {
//               if (onFailed) {
//                 onFailed();
//               }
//             });
//             if (onSuccess) {
//               onSuccess();
//             }
//             setIsMutating(false);
//           })
//           .catch(() => {
//             if (onFailed) {
//               onFailed();
//             }
//             setIsMutating(false);
//           });
//       },
//       []
//     ),
//     isMutating,
//   };
// };
