import { Stack, Text, Title } from "@mantine/core";
import { useGetDailyPurchaseHistories } from "api";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import dayjs from "dayjs";
import DailyHistoryItem from "features/purchaseHistories/components/DailyHistoryItem";
import YearBox from "features/purchaseHistories/components/YearBox";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { Navigate, useParams } from "react-router-dom";

const DailyPurchaseHistories: FC = () => {
  const { year, month } = useParams();

  const targetYear = year
    ? dayjs().year(Number(year)).format("YYYY")
    : dayjs().format("YYYY");
  const targetMonth = month
    ? dayjs()
        // month() の引数は、0から11なので、-1 する
        // https://day.js.org/docs/en/get-set/month
        .month(Number(month) - 1)
        .format("MM")
    : dayjs().format("MM");
  const startOfMonth = `${targetYear}-${targetMonth}-01`;
  const endOfMonth = dayjs(startOfMonth).endOf("month").format("YYYY-MM-DD");
  const { data, isValidating } = useGetDailyPurchaseHistories({
    endDate: endOfMonth,
    startDate: startOfMonth,
  });

  if (isValidating || !data)
    return <Loading pageTitle={PAGE_TITLE.ICHIYAMA_MART} />;

  // 年、月が正しく取得出来ていない場合は、お買い物履歴(月)画面に戻す
  if (!year || !month) return <Navigate to="/purchase-histories" />;

  return (
    <MainLayout pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
      <Stack spacing={12}>
        <Title weight={700} size={20}>
          お買い物履歴
        </Title>
        <Text size="sm" fw={400}>
          最大500件表示されます。
        </Text>

        {data.purchase_histories.length ? (
          <YearBox yearLabel={`${year}年${month}月`} useList>
            {data.purchase_histories.map((purchaseHistory, index) => (
              <DailyHistoryItem key={`history-${index}`} {...purchaseHistory} />
            ))}
          </YearBox>
        ) : (
          <Text color="#9E9E9E" align="center" size={16} weight={700}>
            お買い物履歴がありません。
          </Text>
        )}
      </Stack>
    </MainLayout>
  );
};
export default DailyPurchaseHistories;
