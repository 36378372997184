// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { Center, NavLink, Title, UnstyledButton, rem } from "@mantine/core";
import { ReactComponent as CardReissueIcon } from "assets/images/icons/card_reissue.svg";
import { ReactComponent as ChevronRightIcon } from "assets/images/icons/chevron_right_gray.svg";
import { ReactComponent as MemberInfoIcon } from "assets/images/icons/member_info.svg";
import MainLayout from "components/layouts/MainLayout";
import { PAGE_TITLE } from "libs/constants";
import { Fragment, type FC, type ReactNode } from "react";
import { Link } from "react-router-dom";

const MY_PAGE_ITEMS: {
  icon?: ReactNode;
  isExternal?: boolean;
  label: string;
  path: string;
}[] = [
  {
    icon: <MemberInfoIcon />,
    label: "会員情報",
    path: "./account",
  },
  {
    icon: <CardReissueIcon />,
    label: "カードを再発行した方のお手続き",
    path: "/my-page/card-reregister/top",
  },
  {
    isExternal: true,
    label: "お問い合わせ",
    path: "https://ichiyamamart.com/morecard-contact",
  },
  {
    isExternal: true,
    label: "利用規約",
    path: "https://ichiyamamart.com/app-terms",
  },
  {
    isExternal: true,
    label: "プライバシーポリシー",
    path: "https://ichiyamamart.com/store/morecard#block-yui_3_17_2_1_1671154712024_1123593",
  },
];

const MyPage: FC = () => {
  return (
    <MainLayout pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
      <Title size={20} mb={24}>
        マイページ
      </Title>
      {MY_PAGE_ITEMS.map(({ icon, isExternal, label, path }, index) => {
        return (
          <Fragment key={index}>
            {isExternal ? (
              <UnstyledButton
                display="flex"
                miw="100%"
                px="0"
                py={12}
                sx={{
                  alignItems: "center",
                  borderBottom: "1px solid #e0e0e0",
                  justifyContent: "space-between",
                }}
                onClick={() => liff.openWindow({ external: false, url: path })}
              >
                {label}
                <Center w="1rem" h="1rem">
                  <ChevronRightIcon stroke="#9E9E9E" />
                </Center>
              </UnstyledButton>
            ) : (
              <NavLink
                label={label}
                icon={icon}
                rightSection={<ChevronRightIcon stroke="#9E9E9E" />}
                component={Link}
                to={path}
                px="0"
                py={12}
                styles={(theme) => ({
                  icon: {
                    marginRight: rem(8),
                  },
                  label: {
                    fontSize: rem(14),
                    fontWeight: 500,
                  },
                  rightSection: {
                    height: rem(16),
                    width: rem(16),
                  },
                  root: {
                    borderBottom: "1px solid #e0e0e0",
                  },
                })}
              />
            )}
          </Fragment>
        );
      })}
    </MainLayout>
  );
};

export default MyPage;
