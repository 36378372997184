import { createStyles, NavLink, Stack, Title } from "@mantine/core";
import { useGetMonthlyPurchaseHistories } from "api";
import { ReactComponent as CaretRight } from "assets/images/icons/caret_right.svg";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import dayjs from "dayjs";
import MonthlyHistoryItem from "features/purchaseHistories/components/MonthlyHistoryItem";
import YearBox from "features/purchaseHistories/components/YearBox";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

type PurchaseHistory = {
  purchase_amount: number;
  target_month: string;
};

const useCustomNavStyle = createStyles(() => ({
  root: {
    borderBottom: "1px solid #E0E0E0",
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const transformPurchaseHistoriesToRenderedFormat = (
  purchaseHistories: PurchaseHistory[]
): Record<string, PurchaseHistory[]> | NonNullable<unknown> => {
  return purchaseHistories.reduce<Record<string, PurchaseHistory[]>>(
    (previousValue, currentValue) => {
      const [year, month] = currentValue.target_month.split("-");
      const purchaseHistory = {
        ...currentValue,
        // 月の01の表示 -> 1に変更するため、一回Numberにし、そして文字列に
        target_month: String(Number(month)),
      };
      const yearObject: typeof previousValue = {};
      yearObject[year] = Object.hasOwn(previousValue, year)
        ? [...previousValue[year], purchaseHistory]
        : [purchaseHistory];
      return {
        ...previousValue,
        ...yearObject,
      };
    },
    {}
  );
};
const MonthlyPurchaseHistories: FC = () => {
  const { classes } = useCustomNavStyle();
  const navigate = useNavigate();

  const currentMonth = dayjs().format("YYYY-MM");
  const lastMonth = dayjs().subtract(1, "M").format("YYYY-MM");
  const { data, isValidating } = useGetMonthlyPurchaseHistories({
    endMonth: currentMonth,
    startMonth: lastMonth,
  });

  if (isValidating || !data)
    return <Loading pageTitle={PAGE_TITLE.ICHIYAMA_MART} />;

  const renderFormatData = transformPurchaseHistoriesToRenderedFormat(
    data.purchase_histories
  );

  return (
    <MainLayout pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
      <Stack spacing={24}>
        <Title weight={700} size={20}>
          お買い物履歴
        </Title>
        {Object.entries(renderFormatData)
          .sort(
            ([yearLabelA], [yearLabelB]) =>
              Number(yearLabelB) - Number(yearLabelA)
          )
          .map(([yearLabel, yearPurchaseHistories]) => {
            if (
              Array.isArray(yearPurchaseHistories) &&
              yearPurchaseHistories.length
            ) {
              return (
                <YearBox yearLabel={`${yearLabel}年`} key={yearLabel}>
                  <>
                    {yearPurchaseHistories
                      .sort(
                        (purchaseHistoryA, purchaseHistoryB) =>
                          Number(purchaseHistoryB.target_month) -
                          Number(purchaseHistoryA.target_month)
                      )
                      .map((purchaseHistory, index) => (
                        <NavLink
                          classNames={classes}
                          key={`purchase-history-${yearLabel}-${index}`}
                          rightSection={
                            <CaretRight style={{ color: "#9E9E9E" }} />
                          }
                          label={
                            <MonthlyHistoryItem
                              monthName={purchaseHistory.target_month}
                              purchaseAmount={purchaseHistory.purchase_amount}
                            />
                          }
                          onClick={() =>
                            navigate(
                              `/purchase-histories/${yearLabel}/${purchaseHistory.target_month}`
                            )
                          }
                        />
                      ))}
                  </>
                </YearBox>
              );
            } else {
              return null;
            }
          })}
      </Stack>
    </MainLayout>
  );
};
export default MonthlyPurchaseHistories;
