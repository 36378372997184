import { Box, Text, Title, createStyles, rem } from "@mantine/core";
import { useGetUser } from "api";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";

const useStyles = createStyles(() => ({
  box: {
    borderBottom: "1px solid #e0e0e0",
    paddingBottom: rem(8),
    paddingTop: rem(8),
  },
  label: {
    fontSize: rem(12),
    fontWeight: 700,
    marginBottom: rem(8),
  },
  text: {
    color: "#191919",
    fontSize: rem(14),
  },
}));

const AccountDetail: FC = () => {
  const { classes } = useStyles();
  const { data, isLoading } = useGetUser();

  if (isLoading || !data)
    return <Loading pageTitle={PAGE_TITLE.ICHIYAMA_MART} />;

  return (
    <MainLayout pageTitle={PAGE_TITLE.ICHIYAMA_MART}>
      <Title size={18} mb={24}>
        会員情報
      </Title>
      <Box className={classes.box} mb={8}>
        <Text className={classes.label}>カード番号</Text>
        <Text className={classes.text}>{data.card_number}</Text>
      </Box>
      <Box className={classes.box} mb={8}>
        <Text className={classes.label}>姓名（セイメイ）</Text>
        <Text className={classes.text}>{data.name}</Text>
      </Box>
      <Box className={classes.box}>
        <Text className={classes.label}>郵便番号</Text>
        <Text className={classes.text}>{data.postal_code}</Text>
      </Box>
    </MainLayout>
  );
};

export default AccountDetail;
